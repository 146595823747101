import * as R from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import Head from 'next/head'
import cx from 'classnames'
import { withRouter } from 'next/router'

import { GLOBAL_LAYOUT_STYLES } from 'constants/styles'
import { ASSETS_URL } from 'utilities/urls'
import ModalContext from 'contexts/modal-context'
import { hasTouch } from 'utilities/environment'
import CurrentUserProvider from 'providers/current-user-provider'
import s from './styles.module.css'
import Script from 'next/script'
import { LeadsProvider } from 'contexts/leads-context'
import { AnalyticsManager } from 'utilities'
import useTargetAudience, { Audience } from '../../hooks/use-target-audience'
import { useSafeAreaInsets } from 'hooks/use-safe-area-insets'

config.autoAddCss = false

// for use in console
if (process.browser && process.env.APP_ENV === 'development') {
  window.Ramda = require('ramda')
}

const DEFAULT_MODAL_THEME = 'white'

const GTM_ID =
  process.env.APP_ENV !== 'production' ? 'GTM-56MWNNX' : 'GTM-TLW9XW3'

const IS_PROD = process.env.APP_ENV === 'production'
const IS_STAGING = process.env.APP_ENV === 'staging'

const Layout = ({
  children,
  router,
  canonicalUrl = undefined,
  title = 'Kickoff',
  metaTags = [],
  noIndex = false,
  includeChat = false,
  includeCloudinary = false,
  clientFirstName = undefined,
  disableAutoScale = undefined,
  fontFamily = 'Heebo',
  appClass = undefined,
  referrer = undefined,
  userAgent = undefined,
  isFreeTrial = false,
  applySafeAreaInsets = true,
  appStyles = {},
}) => {
  const blockIndex = noIndex || !IS_PROD
  const getCanonicalUrl = () => {
    if (canonicalUrl) return canonicalUrl
    if (!IS_PROD) {
      const { asPath } = router
      return `https://www.trainwithkickoff.com${asPath}`
    }

    return null
  }
  const canonicalHref = getCanonicalUrl()
  const targetAudience = useTargetAudience()
  const isTYPGuestPass = targetAudience === Audience.ThankYouPageGuestPass

  const [renderModal, setRenderModal] = useState(null)
  const [modalProps, setModalProps] = useState(null)
  const [modalConfig, setModalConfig] = useState({})
  const [modalKey, setModalKey] = useState(null)
  const insets = useSafeAreaInsets()

  useEffect(() => {
    if (router?.query) {
      if (router?.query?.deviceId) {
        AnalyticsManager.setDeviceId(router?.query?.deviceId)
      }

      AnalyticsManager.setUserProperties({ ...router.query })
    }
  }, [router?.query])

  const closeModal = () => {
    modalConfig.onClose?.()
    setRenderModal(null)
    setModalProps(null)
    setModalConfig({})
  }

  const modalState = {
    isOpen: !!renderModal,
    open: (
      renderModal,
      modalProps,
      {
        className,
        overlayClassName,
        theme = DEFAULT_MODAL_THEME,
        disableClose = false,
        blur = false,
        key = null,
        onClose,
      } = {}
    ) => {
      setRenderModal(() => renderModal)
      setModalProps(modalProps)
      setModalConfig({
        className,
        overlayClassName,
        theme,
        disableClose,
        blur,
        onClose,
      })
      setModalKey(key)
    },
    setProps: setModalProps,
    setConfig: newConfig => setModalConfig({ ...modalConfig, ...newConfig }),
    close: closeModal,
    isBlur: modalConfig.blur,
    key: modalKey,
  }

  const viewportContentItems = ['width=device-width', 'initial-scale=1.0']

  if (disableAutoScale) {
    viewportContentItems.push('maximum-scale=1.0, user-scalable=no')
  }

  const [touchClasses, setTouchClasses] = useState({})

  useEffect(() => {
    const deviceHasTouch = hasTouch()

    setTouchClasses({
      '--has-touch': deviceHasTouch,
      '--has-hover': !deviceHasTouch,
    })
  }, [])

  const style = useMemo(() => {
    if (!applySafeAreaInsets) return null
    if (!insets.top && !insets.bottom) return null

    return { paddingTop: insets.top, paddingBottom: insets.bottom }
  }, [applySafeAreaInsets, insets.bottom, insets.top])

  return (
    <>
      <div
        id="app"
        style={{
          ...appStyles,
          ...style,
        }}
        className={cx(
          s.app,
          s[`fontFamily${fontFamily}`],
          appClass,
          touchClasses
        )}>
        <Head>
          <meta content={viewportContentItems.join(', ')} name="viewport" />
          <meta name="google" value="notranslate" />

          {blockIndex && <meta name="robots" content="noindex,nofollow" />}
          {canonicalHref && (
            <link rel="canonical" href={canonicalHref} key="canonical" />
          )}

          {R.addIndex(R.map)(
            ({ fields }, i) => {
              const attrs = R.pipe(
                ({ name, property, content, contentMedia }) => {
                  const getContent = () => {
                    if (contentMedia) {
                      return `https:${R.path(
                        ['fields', 'file', 'url'],
                        contentMedia
                      )}`
                    }

                    return content
                  }
                  return {
                    name,
                    property,
                    content: getContent(),
                  }
                },
                R.filter(R.identity)
              )(fields)

              return <meta key={`meta-${i}`} {...attrs} />
            },
            R.reject(
              mt =>
                (clientFirstName &&
                  (mt.fields.property === 'og:image' ||
                    mt.fields.property === 'og:title' ||
                    mt.fields.property === 'og:description' ||
                    mt.fields.name === 'description')) ||
                (isFreeTrial &&
                  (mt.fields.property === 'og:description' ||
                    mt.fields.name === 'description')),
              metaTags
            )
          )}

          {clientFirstName && (
            <>
              <meta
                property="og:title"
                content={`1 ${
                  isTYPGuestPass ? 'Week' : 'Month'
                } Free Guest Pass | From: ${clientFirstName}`}
              />
              <meta
                property="og:description"
                content={`Find your coach & get daily online personal training for free.`}
              />
              <meta
                name="description"
                content={`Find your coach & get daily online personal training for free.`}
              />
              {isTYPGuestPass ? (
                <meta
                  property="og:image"
                  content={`${ASSETS_URL}/giftcard-7-day.png`}
                />
              ) : (
                <meta
                  property="og:image"
                  content={`${ASSETS_URL}/giftcard-with-expire-date-v3.png`}
                />
              )}
            </>
          )}

          {isFreeTrial && (
            <>
              <meta
                property="og:description"
                content="Daily custom workout & nutrition plan curated by your own human fitness coach. On-demand support. 7 day free trial."
              />
              <meta
                name="description"
                content="Daily custom workout & nutrition plan curated by your own human fitness coach. On-demand support. 7 day free trial."
              />
            </>
          )}

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${ASSETS_URL}/apple-touch-icon-v3.png`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${ASSETS_URL}/favicon-v3-32x32.png`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${ASSETS_URL}/favicon-v3-16x16.png`}
            sizes="16x16"
          />

          <title key="title">{title}</title>
        </Head>

        {includeChat && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="${process.env.CRISP_WEBSITE_ID}";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
            }}
          />
        )}

        {includeCloudinary && (
          <Script
            src="https://widget.cloudinary.com/v2.0/global/all.js"
            type="text/javascript"
          />
        )}

        <ModalContext.Provider value={modalState}>
          <LeadsProvider referrer={referrer} userAgent={userAgent}>
            {children}

            {!!renderModal && (
              <CurrentUserProvider>
                <div className={s.modalWrapper}>
                  <div className={cx(s.modal, modalConfig.className)}>
                    {renderModal(modalProps)}
                  </div>
                  <div
                    className={cx(
                      s.modalOverlay,
                      {
                        [s.modalOverlayWhite]: modalConfig.theme === 'white',
                        [s.modalOverlayBlack]: modalConfig.theme === 'black',
                      },
                      modalConfig.overlayClassName
                    )}
                    onClick={() => {
                      if (!modalConfig.disableClose) {
                        closeModal()
                      }
                    }}
                  />
                </div>
              </CurrentUserProvider>
            )}
          </LeadsProvider>
        </ModalContext.Provider>
      </div>
      {GLOBAL_LAYOUT_STYLES}
      {(IS_PROD || IS_STAGING) && (
        <>
          {/* Facebook Pixel Code */}
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${
                  process.env.APP_ENV === 'production'
                    ? '200812520632487'
                    : '514786171554910'
                });
                fbq('track', 'PageView');
            `,
            }}
          />
        </>
      )}
      {IS_PROD && (
        <>
          {/* Hotjar Tracking Code for www.kudos.fit */}
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:1175356,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
            }}
          />

          {/* Microsoft UET Tag */}
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"17431571"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`,
            }}
          />
        </>
      )}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ APP_ENV: '${process.env.APP_ENV}' })
            `,
        }}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${GTM_ID}');
              `,
        }}
      />
      <noscript>
        <iframe
          title="GTM"
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  )
}

export default withRouter(Layout)
