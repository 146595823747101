export const ENVIRONMENT_ENDPOINTS = {
  production:
    'https://v82hc6meb4.execute-api.us-west-1.amazonaws.com/production/graphql/',
  beta: 'https://797zeqguwl.execute-api.us-west-1.amazonaws.com/beta/graphql/',
  staging:
    'https://kr82977pc4.execute-api.us-west-1.amazonaws.com/staging/graphql/',
  growth:
    'https://1sbu7pf2oj.execute-api.us-west-1.amazonaws.com/growth/graphql/',
  development: 'http://localhost:4000/development/graphql',
}
